import axios from 'axios'
import qs from 'qs'
// import router from '../router/index.js';
//  const ConfigBaseURL = 'http://testxz.tsingd.cn/';
const local = location.origin.indexOf('localhost') > -1 ? true : false;
//  const ConfigBaseURL = local ? 'http://10.10.2.32' : '../..';
//  const ConfigBaseURL = 'http://10.10.2.32';
 const ConfigBaseURL = local? window._config.domain1:window._config1.domain1;
const Service = axios.create({
    timeout: 10000, // 请求超时时间
    baseURL: ConfigBaseURL,
    headers: {
        // "Content-Type": "application/x-www-form-urlencoded",
        // "X-key": '5f5dac42-24bf-47b2-be6f-703a1308bedb'
        // "X-key": 8059
    },
    withCredentials: false,
    changeOrigin:true,
})
// 请求拦截器
Service.interceptors.request.use(
    config => {
        const userApiToken = localStorage.getItem('userApiToken')
        const userid = localStorage.getItem('userid')||''
        // console.log(userid)
        if(userid){
            return config
        }
        if (userApiToken) {
            if (config.params) {
                config.params.userApiToken = userApiToken;
            } else {
                let data = qs.parse(config.data)
                data.userApiToken = userApiToken;
                config.data = qs.stringify(data);
            }
        }
        else if(!config.unLogin){
            // router.push('/login')
        }
        return config
    },
    error => {
        return Promise.reject(error)
    })

// 响应拦截器
Service.interceptors.response.use(
    response => {
        if (response.status === 200) {
            return Promise.resolve(response.data)
        } else {
            return Promise.reject(response)
        }
    },
    error => {
        return Promise.reject(error)

    }
)

/**
 * 
 * @param {string} url 地址 
 * @param {*} data 数据
 * @param {boolean} unLogin 是否不携带登陆参数，默认为false，携带登陆参数
 * @return {Promise<{code:string|number,info:string,message:string,data:any}>} 基本信息，其中info、message二者可能出现一个
 */
export function get(url, data,unLogin=false) {
    return new Promise((resolve, reject) => {
        Service.get(url, { params: data,unLogin })
            .then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
    })
}

/**
 * 
 * @param {string} url 地址 
 * @param {*} data 数据
 * @param {boolean} unLogin 是否不携带登陆参数，默认为false，携带登陆参数
 * @return {Promise<{code:string|number,info:string,message:string,data:any}>} 基本信息，其中info、message二者可能出现一个
 */
export function post(url, data,unLogin=false) {
    return new Promise((resolve, reject) => {
        Service.post(url, qs.stringify(data),{unLogin})
            .then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
    })
}
export { ConfigBaseURL }