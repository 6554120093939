import { get,post } from './http.js';
// import axios from 'axios'
// export const reqBaseCategoryList = () => ajax('/product/getBaseCategoryList')
export function raninfo(params = {}) { //获取随机数
    return get('/arena/raninfo', params)
}
export function prolist(params = {}) { //获取擂台场次
    return get('/arena/prolist', params)
}
export function grouplist(params = {}) { //获取擂台分组
    return get('/arena/grouplist', params)
}
export function countlist(params = {}) { //获取主表的所有列表数据
    return get('/arena/countlist', params)
}
export function deplist(params = {}) { //获取分租部门
    return get('/arena/deplist', params)
}
export function savescore(params = {}) { //保存分数
    return post ('/arena/savescore',params)
}
export function savescorelist(params = {}) { //提交分数
    return post ('/arena/savescorelist',params)
}
export function depscorelist(params = {}) { //提交分数
    return get ('/arena/depscorelist',params)
}
export function groupanddeplist(params = {}) { //获取揽客
    return get ('/arena/groupanddeplist',params)
}
export function depshowlist(params = {}) { //获取pc端页面数据
    return get ('/arena/depshowlist',params)
}
export function check(params = {}) { //获取权限
    return get ('/arena/check',params)
}
